import React from "react";
import Section from "../components/elements/Section";

export default function Footer() {
  return (
    <Section as="footer" className="mc-footer">
      © All Rights Reserved by ♥ Qorva Markets
    </Section>
  );
}
