import React, { useState } from "react";
import { useQuery } from "react-query";
import { getAllUsersServices } from "../../services/admin.service";
import { LabelField } from "../fields";
import _ from "lodash";
import CustomReactSelect from "../fields/CustomReactSelect";

const SearchUserField = ({
  userId,
  onChangeUserId,
  fieldValueProp,
  userType = ""
}) => {
  const { isLoading, error, data, refetch } = useQuery(
    ["search-all-users-" + userType, userType],
    () => getAllUsersServices(1, userType)
  );

  const [value, setValue] = useState(undefined);

  if (isLoading) return <></>;

  const getEmails = () => {
    let options = [{ label: "Select All", value: undefined }];

    let users = data?.data?.data?.users?.data ?? [];

    users = _.filter(users, (_user) =>
      _.isEmpty(_user?.email) ? false : true
    );

    users = _.sortBy(users, "email", "ASC");

    options = [
      ...options,
      ..._.map(users, (_user) => {
        return {
          label: `(${_user?.id}) ${_user?.email}`,
          value: fieldValueProp ? _user[fieldValueProp] : _user?.id
        };
      })
    ];

    return options;
  };

  return (
    <CustomReactSelect
      label="Select Email"
      options={getEmails()}
      value={value}
      isLoading={isLoading}
      onChange={(e) => {
        setValue(e);
        if (e?.value) {
          onChangeUserId(fieldValueProp ? e?.value : parseInt(e?.value));
        } else {
          onChangeUserId(undefined);
        }
      }}
      optionStyles={{ textTransform: "lowercase" }}
      style={{ textTransform: "lowercase" }}
    />
  );
};

export default SearchUserField;
